<app-editable-grid
    [pageable]="false"
    [data]="translations"
    [editMode]="editMode"
    [unique]="uniqueProperties"
    [canAddNew]="notSetLanguagesCount > 0"
    [removeOnDelete]="false"
    (saveEvent)="saveHandler($event)"
    (removeEvent)="removeHandler($event)"
    (rowAction)="handleRowAction($event)">
    <kendo-grid-column title="{{ 'Language' | translate }}" field="languageId" [editable]="editMode" [sortable]>
        <ng-template kendoGridEditTemplate let-row="dataItem">
            <app-control
                type="codelist"
                codelist="Language"
                [omittedCodelistIds]="existingTranslationsLanguageIds()"
                [required]="true"
                [(ngModel)]="toTranslation(row).languageId" />
        </ng-template>
        <ng-template kendoGridCellTemplate let-row>
            <span kendoTooltip title="Language">
                {{ toTranslation(row).languageId | codelist: 'Language' | async }}
            </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{ 'Localized Name' | translate }}" field="name">
        <ng-template kendoGridEditTemplate let-row="dataItem">
            <app-control type="string" [required]="true" [(ngModel)]="toTranslation(row).name"></app-control>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{ 'Localized Code' | translate }}" field="localizedCode">
        <ng-template kendoGridEditTemplate let-row="dataItem">
            <app-control type="string" [required]="true" [(ngModel)]="toTranslation(row).localizedCode"></app-control>
        </ng-template>
    </kendo-grid-column>
</app-editable-grid>
