import { CodelistsService } from '@administration/codelists/codelists.service';
import { CodeListTranslationsComponent } from '@administration/codelists/components/codelist-translations/codelist-translations.component';
import { DatePipe, CommonModule as NgCommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { WebsocketService } from '@common/services/websocket.service';
import { NgIconsModule } from '@ng-icons/core';
import { SubscribeDirective } from '@ngneat/subscribe';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule, ChipModule, DropDownButtonModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule, DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { EditorModule } from '@progress/kendo-angular-editor';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { IntlModule } from '@progress/kendo-angular-intl';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { PopoverModule, TooltipModule } from '@progress/kendo-angular-tooltip';
import { FileSelectModule } from '@progress/kendo-angular-upload';
import { NotificationListComponent } from 'app/notice-board/components/notification-list/notification-list.component';
import { DateFormatSelectorComponent } from 'app/user-settings/components/date-format-selector/date-format-selector.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import icons from './classes/icons';
import { ABDropdownMenuComponent } from './components/action-bar/abdropdown-menu/abdropdown-menu.component';
import { ActionBarComponent } from './components/action-bar/action-bar.component';
import { ActiveFiltersComponent } from './components/active-filters/active-filters.component';
import { AnimatedSpanComponent } from './components/animated-span/animated-span.component';
import { AppCardComponent } from './components/app-card/app-card.component';
import { AppControlComponent } from './components/app-control/app-control.component';
import { AppFilterComponent } from './components/app-filter/app-filter.component';
import { AppFormComponent } from './components/app-form.component';
import { AppGridComponent } from './components/app-grid/app-grid.component';
import { GridCellTemplateComponent } from './components/app-grid/columns/grid-cell-template/grid-cell-template.component';
import { GridEditTemplateComponent } from './components/app-grid/columns/grid-edit-template/grid-edit-template.component';
import { AppLinkComponent } from './components/app-link.component';
import { AppMetaDataComponent } from './components/app-meta-data/app-meta-data.component';
import { MetaDataItemComponent } from './components/app-meta-data/meta-data-item/meta-data-item.component';
import { ApprovalTabComponent } from './components/approval-tab/approval-tab.component';
import { AttachmentsTabComponent } from './components/attachments-tab/attachments-tab.component';
import { ButtonComponent } from './components/button/button.component';
import { CheckIconComponent } from './components/check-icon.component';
import { ContentHeaderComponent } from './components/content-header.component';
import { DialogConfirmComponent } from './components/dialog-confirm';
import { DialogErrorComponent } from './components/dialog-error.component';
import { DialogFormComponent } from './components/dialog-form/dialog-form.component';
import { AppDrawerComponent } from './components/drawer/drawer.component';
import { EditableGridComponent } from './components/editable-grid/editable-grid.component';
import { FlagIconComponent } from './components/flag-icon.component';
import { FooterComponent } from './components/footer.component';
import { BooleanInputComponent } from './components/input/boolean-input/boolean-input.component';
import { CodelistInputComponent } from './components/input/codelist-input/codelist-input.component';
import { DateInputComponent } from './components/input/date-input/date-input.component';
import { DaterangeInputComponent } from './components/input/daterange-input/daterange-input.component';
import { DatetimeInputComponent } from './components/input/datetime-input/datetime-input.component';
import { EditorInputComponent } from './components/input/editor-input/editor-input.component';
import { FileInputComponent } from './components/input/file-input/file-input.component';
import { InputErrorComponent } from './components/input/input-error/input-error.component';
import { InputLabelComponent } from './components/input/input-label/input-label.component';
import { InputComponent } from './components/input/input.component';
import { NumberInputComponent } from './components/input/number-input/number-input.component';
import { SelectInputComponent } from './components/input/select-input/select-input.component';
import { TextInputComponent } from './components/input/text-input/text-input.component';
import { TextareaInputComponent } from './components/input/textarea-input/textarea-input.component';
import { LoaderComponent } from './components/loader.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout.component';
import { MeanOfTransportComponent } from './components/mean-of-transport/mean-of-transport.component';
import { RadioButtonSelectionComponent } from './components/radio-button-selection/radio-button-selection.component';
import { SealComponent } from './components/seal/seal.component';
import { SidebarProfileComponent } from './components/sidebar-profile/sidebar-profile.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { ToggleGroupComponent } from './components/toggle-group/toggle-group.component';
import { UnauthorizedComponent } from './components/unauthorized.component';
import { UploadActionButtonsComponent } from './components/upload-action-buttons.component';
import { UploadMultipleFilesComponent } from './components/upload-multiple-files.component';
import { UploadSingleFileComponent } from './components/upload-single-file.component';
import { VesselTypeComponent } from './components/vessel-type/vessel-type.component';
import { PillDirective } from './directives/pill.directive';
import { SetEditModeDirective } from './directives/set-edit-mode.directive';
import { EntityErrorsComponent } from './entity-errors/entity-errors.component';
import { AuthGuard } from './guards/auth.guard';
import { AbbreviatePipe } from './pipes/abbreviate.pipe';
import { ActivePipe } from './pipes/active.pipe';
import { CodelistPipe } from './pipes/codelist.pipe';
import { CustomDatePipe } from './pipes/date.pipe';
import { DateTimePipe } from './pipes/datetime.pipe';
import { PadPipe } from './pipes/pad.pipe';
import { CommonService } from './services/common.service';
import { DarkModeService } from './services/dark-mode.service';
import { DialogService } from './services/dialog.service';
import { QueryService } from './services/query.service';
import { RememberStateService } from './services/remember-state.service';
import { ToastrNotificationService } from './services/toastr-notification.service';

@NgModule({
    declarations: [
        // Components
        ActionBarComponent,
        ActiveFiltersComponent,
        AppCardComponent,
        AppControlComponent,
        AppDrawerComponent,
        AppFilterComponent,
        AppFormComponent,
        AppGridComponent,
        AppLinkComponent,
        AppMetaDataComponent,
        ApprovalTabComponent,
        AttachmentsTabComponent,
        BooleanInputComponent,
        ButtonComponent,
        CheckIconComponent,
        CodeListTranslationsComponent,
        CodelistInputComponent,
        ContentHeaderComponent,
        DateFormatSelectorComponent,
        DateInputComponent,
        DaterangeInputComponent,
        DatetimeInputComponent,
        DialogConfirmComponent,
        DialogErrorComponent,
        DialogFormComponent,
        EditableGridComponent,
        EditorInputComponent,
        EntityErrorsComponent,
        FileInputComponent,
        FlagIconComponent,
        FooterComponent,
        GridCellTemplateComponent,
        GridEditTemplateComponent,
        InputComponent,
        InputErrorComponent,
        InputLabelComponent,
        LoaderComponent,
        LoginComponent,
        LogoutComponent,
        MeanOfTransportComponent,
        MetaDataItemComponent,
        NotificationListComponent,
        NumberInputComponent,
        RadioButtonSelectionComponent,
        SealComponent,
        SelectInputComponent,
        SidebarComponent,
        SidebarProfileComponent,
        StepperComponent,
        TextInputComponent,
        TextareaInputComponent,
        ToggleGroupComponent,
        UnauthorizedComponent,
        UploadActionButtonsComponent,
        UploadMultipleFilesComponent,
        UploadSingleFileComponent,
        VesselTypeComponent,
        // Pipes
        ActivePipe,
        CodelistPipe,
        CustomDatePipe,
        DateTimePipe,
        PadPipe,
        // Directives
        PillDirective,
        SetEditModeDirective
    ],
    exports: [
        // Modules
        ButtonModule,
        ChipModule,
        DateInputsModule,
        DatePickerModule,
        DialogModule,
        DropDownButtonModule,
        DropDownsModule,
        ExcelModule,
        FormsModule,
        GridModule,
        IndicatorsModule,
        InputsModule,
        IntlModule,
        LayoutModule,
        ListViewModule,
        NavigationModule,
        NgCommonModule,
        NgIconsModule,
        NgxJsonViewerModule,
        PDFExportModule,
        PopoverModule,
        ReactiveFormsModule,
        RouterModule,
        ToggleGroupComponent,
        TooltipModule,
        TranslateModule,
        ListViewModule,
        // Components
        ABDropdownMenuComponent,
        ActionBarComponent,
        ActiveFiltersComponent,
        AnimatedSpanComponent,
        AppCardComponent,
        AppControlComponent,
        AppDrawerComponent,
        AppFilterComponent,
        AppFormComponent,
        AppGridComponent,
        AppLinkComponent,
        AppMetaDataComponent,
        ApprovalTabComponent,
        AttachmentsTabComponent,
        BooleanInputComponent,
        ButtonComponent,
        CheckIconComponent,
        CodeListTranslationsComponent,
        CodelistInputComponent,
        ContentHeaderComponent,
        DateFormatSelectorComponent,
        DateInputComponent,
        DaterangeInputComponent,
        DatetimeInputComponent,
        DialogConfirmComponent,
        DialogErrorComponent,
        DialogFormComponent,
        EditableGridComponent,
        EditorInputComponent,
        EntityErrorsComponent,
        FileInputComponent,
        FlagIconComponent,
        FooterComponent,
        InputComponent,
        InputErrorComponent,
        InputLabelComponent,
        LoaderComponent,
        MetaDataItemComponent,
        NumberInputComponent,
        RadioButtonSelectionComponent,
        SelectInputComponent,
        SidebarComponent,
        SidebarProfileComponent,
        StepperComponent,
        TextInputComponent,
        TextareaInputComponent,
        VesselTypeComponent,
        // Pipes
        ActivePipe,
        CodelistPipe,
        CustomDatePipe,
        DateTimePipe,
        PadPipe,
        //Directives
        PillDirective,
        SetEditModeDirective,
        SubscribeDirective
    ],
    imports: [
        // Modules
        ButtonModule,
        ChipModule,
        DateInputsModule,
        DatePickerModule,
        DialogModule,
        DropDownButtonModule,
        DropDownsModule,
        EditorModule,
        ExcelModule,
        EditorModule,
        FileSelectModule,
        FormsModule,
        GridModule,
        IndicatorsModule,
        InputsModule,
        IntlModule,
        LabelModule,
        LayoutModule,
        ListViewModule,
        NavigationModule,
        NgCommonModule,
        NgIconsModule.withIcons(icons),
        NgxJsonViewerModule,
        PDFExportModule,
        ReactiveFormsModule,
        RouterModule,
        TooltipModule,
        TranslateModule.forChild({ extend: true }),
        ListViewModule,
        PopoverModule,
        LabelModule,
        // Components
        ABDropdownMenuComponent,
        AnimatedSpanComponent,
        // Pipes
        AbbreviatePipe,
        //Directives
        SubscribeDirective
    ],
    providers: [
        AuthGuard,
        CodelistPipe,
        CommonService,
        CodelistsService,
        CustomDatePipe,
        DarkModeService,
        DatePipe,
        DateTimePipe,
        DialogService,
        QueryService,
        RememberStateService,
        ToastrNotificationService,
        WebsocketService
    ]
})
export class CommonModule {}
