@if (currentVesselType) {
    <span>
        {{ currentVesselType | codelist: currentVesselTypeLevel | async }}
    </span>
    <ng-icon
        class="cursor-pointer text-blue-700 hover:text-blue-500"
        size="12px"
        name="faSolidCircleInfo"
        kendoPopoverAnchor
        kendoButton
        [popover]="vesselTypePopover" />
    <kendo-popover #vesselTypePopover position="bottom">
        <ng-template kendoPopoverBodyTemplate>
            <div class="flex flex-col gap-2 text-xs">
                @for (level of vesselTypeLevels; track $index) {
                    @if (model[camelCaseCodelist(level)]) {
                        <div>
                            <span class="text-muted">
                                {{ 'Vessel Type Level' | translate }} {{ $index + 1 }}:&nbsp;
                            </span>
                            <span class="font-bold">
                                {{ model[camelCaseCodelist(level)] | codelist: camelCaseCodelist(level) | async }}
                            </span>
                        </div>
                    }
                }
            </div>
        </ng-template>
    </kendo-popover>
}
