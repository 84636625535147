@if (model.length === 1) {
    {{ model[0].number }}
} @else if (model.length > 1) {
    <span class="flex flex-row items-center gap-2">
        {{ model.length }}
        <ng-icon
            class="cursor-pointer text-blue-700 hover:text-blue-500"
            size="12px"
            name="faSolidCircleInfo"
            kendoTooltip
            title="{{ seals }}" />
    </span>
}
