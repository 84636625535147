<app-grid
    selectBy="row"
    [data]="query()"
    [buttons]="buttons"
    [nrOfActiveFilters]="nrOfActiveFilters"
    [cols]="columns"
    [parentRoute]="parentRoute"
    [defaultViewMode]="defaultViewMode"
    [sortable]="true"
    [selectable]="true"
    [queryData]="search"
    [selection]="selection"
    (saveGridSettings)="saveGridSettings($event)"
    (deleteGridSettings)="deleteGridSettings()"
    (selectedKeysChange)="onSelectedKeysChange($event)"
    (clearFilter)="clearFilter()"
    (toggleFilter)="filterDialog(filterProperties)" />
