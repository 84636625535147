import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-mean-of-transport',
    templateUrl: './mean-of-transport.component.html'
})
export class MeanOfTransportComponent implements OnInit {
    @Input() model: any;
    mots = '';

    ngOnInit() {
        this.mots = this.model.map((item) => item).join(', ');
    }
}
