import { Component, Input, OnInit } from '@angular/core';
import { BaseListComponent } from '@common/classes/base-list';
import { ColumnSettings } from '@common/interfaces/column-settings.interface';
import { ViewMode } from '@common/models/view-mode';
import { CommonService } from '@common/services/common.service';
import { ActionBarGroup } from '../action-bar/action-bar.interface';
import { FormProperty } from '../app-control/app-control.component';

@Component({
    selector: 'app-attachments-tab',
    templateUrl: './attachments-tab.component.html',
    providers: [CommonService]
})
export class AttachmentsTabComponent extends BaseListComponent implements OnInit {
    @Input() entityId: number;
    @Input() queryName: string;
    @Input() columns: ColumnSettings[];
    @Input() properties: FormProperty[];
    @Input() override createPermission: string;
    @Input() editPermission: string;
    @Input() removePermission: string;

    buttons: ActionBarGroup[] = [
        {
            label: 'Actions',
            items: [
                {
                    label: 'New',
                    icon: 'faSolidPlus',
                    iconOnly: true,
                    variant: 'primary',
                    isVisible: () => this.canCreateNew(),
                    onClick: () => this.openItem()
                },
                {
                    label: 'Edit',
                    icon: 'faSolidPenToSquare',
                    iconOnly: true,
                    variant: 'primary',
                    isVisible: () => this.canEdit(),
                    isDisabled: () => this.disableAction() || this.selection.length !== 1,
                    onClick: () => this.openItem(this.selection[0])
                },
                {
                    label: 'Delete',
                    icon: 'faSolidTrash',
                    iconOnly: true,
                    variant: 'danger',
                    isVisible: () => this.canRemove(),
                    isDisabled: () => this.disableAction() || this.selection.length <= 0,
                    onClick: () => this.removeItem()
                }
            ]
        }
    ];

    get filterProperties(): FormProperty[] {
        return [
            {
                name: 'attachmentTypeId',
                label: 'Attachment Type',
                type: 'codelist',
                codelist: 'AttachmentType',
                colSpan: 2,
                initialValue: this.filter?.attachmentTypeId
            },
            { name: 'description', label: 'Description', colSpan: 2, initialValue: this.filter?.description },
            { name: 'documentNumber', label: 'Document Number', colSpan: 2, initialValue: this.filter?.documentNumber },
            { name: 'documentIssuer', label: 'Document Issuer', colSpan: 2, initialValue: this.filter?.documentIssuer },
            { name: 'remark', label: 'Remark', colSpan: 2, initialValue: this.filter?.remark }
        ];
    }

    constructor(commonService: CommonService) {
        super(commonService);
    }

    override getDefaultFilter(): {} {
        return {
            id: this.entityId
        };
    }

    canEdit() {
        if (this.editPermission) return this.user.hasPermission(this.editPermission);
        return true;
    }

    async openItem(item: any = null) {
        const data = await this.dialogService.form({
            options: {
                title: `${item ? 'Edit' : 'Add'} Attachment`,
                properties: item ? this.initializeValues(item) : this.properties
            },
            dialogSettings: { width: '40%' }
        });
        if (!data) return;
        this.saveAttachment(
            { ...data, id: item?.id, name: data.file?.name, content: data.file?.content, active: true },
            item ? ViewMode.edit : ViewMode.create
        );
    }

    initializeValues(item: {}): FormProperty[] {
        let props = this.properties.map((prop) => ({ ...prop }));
        if (item) {
            const fileDisplay: FormProperty = {
                name: 'fileName',
                label: 'File Name',
                isDisabled: () => true,
                colSpan: 8
            };
            props = props.map((prop) => (prop.type === 'file' ? fileDisplay : prop));
        }
        props.forEach((prop) => (prop.initialValue = item?.[prop.name]));
        return props;
    }

    canRemove(): boolean {
        return this.user.hasPermission(this.removePermission);
    }

    async removeItem(selection = this.selection) {
        const data = await this.dialogService.confirm({
            options: {
                title: 'Remove Selected Items',
                message: 'Are you sure you want to remove the selected items?'
            }
        });
        if (!data) return;
        selection.forEach((item) => this.saveAttachment({ ...item, active: false }, ViewMode.edit));
    }

    saveAttachment(saveModel: {}, action: string) {
        this.executeCommand(`Save${this.getBaseName()}`, {
            saveModel,
            entityId: this.entityId,
            action
        });
    }

    disableAction() {
        return !this.selection.some(
            (item) => this.user.isSystemUser || item.organizationId === this.user?.organizationId
        );
    }
}
