import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconKey } from '@common/classes/icons';
import { CommonService } from '@common/services/common.service';

export type ToggleGroupItem = {
    icon?: IconKey;
    iconClass?: string | string[] | Set<string> | { [key: string]: any };
    label?: string;
    count?: number;
    isActive?: boolean;
    isDisabled?: boolean;
    value: number | string | number[] | string[];
};

@Component({
    selector: 'app-toggle-group',
    templateUrl: './toggle-group.component.html'
})
export class ToggleGroupComponent implements OnInit {
    @Input() id = '';
    @Input() items: ToggleGroupItem[];
    @Input() size: 'sm' | 'md' | 'lg' = 'md';
    @Input() isSelectionRequired = false;
    @Input() shouldPersistState = true;
    @Output() itemToggled = new EventEmitter<number | string | number[] | string[]>();

    activeIndex: number;

    constructor(private common: CommonService) {}

    ngOnInit(): void {
        if (this.shouldPersistState) {
            this.activeIndex = this.common.rememberStateService.get<number>(
                `toggleGroup:${this.common.getSanitizedUrl()}/${this.id}`
            );
            if (![null, undefined].includes(this.activeIndex) && this.items?.length) {
                this.itemToggled.emit(this.items[this.activeIndex].value);
                return;
            }
        }

        const itemsActiveByDefault = this.items?.filter((item) => item.isActive);

        if (this.isSelectionRequired && itemsActiveByDefault?.length === 0) {
            throw new Error('If isSelectionRequired is true, at least one filter must be active by default');
        }
        if (itemsActiveByDefault?.length > 1) {
            throw new Error('Only one toggleable filter may be active by default');
        }
        if (itemsActiveByDefault?.some((item) => item.isActive && item.isDisabled)) {
            throw new Error('A filter that is active by default may not be disabled');
        }
        if (itemsActiveByDefault?.length === 1) {
            this.toggle(this.items.indexOf(itemsActiveByDefault[0]));
        }
    }

    toggle(index: number) {
        if (this.isSelectionRequired && this.activeIndex === index) return;
        this.activeIndex = index !== this.activeIndex ? index : null;
        this.itemToggled.emit(this.activeIndex !== null ? this.items[this.activeIndex]?.value : null);
        if (this.shouldPersistState) {
            this.common.rememberStateService.set<number>(
                `toggleGroup:${this.common.getSanitizedUrl()}/${this.id}`,
                this.activeIndex
            );
        }
    }
}
