import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-seal',
    templateUrl: './seal.component.html'
})
export class SealComponent implements OnInit {
    @Input() model: any;
    seals = '';

    ngOnInit() {
        this.seals = this.model.map((item) => item.number).join(', ');
    }
}
