import { FakedCodelists } from '@administration/codelists/codelists.interface';
import { Codelists } from '@administration/codelists/codelists.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-vessel-type',
    templateUrl: './vessel-type.component.html'
})
export class VesselTypeComponent implements OnInit {
    @Input() model: any;
    currentVesselType: string;
    currentVesselTypeLevel: Codelists | FakedCodelists;
    vesselTypeLevels: FakedCodelists[] = [
        'VesselTypeLevel1',
        'VesselTypeLevel2',
        'VesselTypeLevel3',
        'VesselTypeLevel4',
        'VesselTypeLevel5'
    ];
    constructor() {}

    ngOnInit() {
        for (const level of this.vesselTypeLevels) {
            if (this.model[this.camelCaseCodelist(level)]) {
                this.currentVesselType = this.model[this.camelCaseCodelist(level)];
                this.currentVesselTypeLevel = level;
            }
        }
    }

    camelCaseCodelist(codelist: Codelists | FakedCodelists): Codelists | FakedCodelists {
        return (codelist.charAt(0).toLowerCase() + codelist.slice(1)) as Codelists | FakedCodelists;
    }
}
