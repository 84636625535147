import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
    selector: '[pill]'
})
export class PillDirective implements OnChanges {
    // Colors
    dark = '#222';
    light = '#fff';
    green = '#5DD879';
    darkgreen = '#2DBC4E';
    red = '#E56B77';
    darkred = '#D44B59';
    blue = '#59A9FF';
    orange = '#FD9A47';
    amber = '#FFC000';
    yellow = '#FFC107';
    grey = '#CED4DA';
    purple = '#BA55D3';
    black = '#000';

    // Status, module & muted input
    @Input() pill: { status: string; module: string };

    el: ElementRef;

    constructor(el: ElementRef) {
        this.el = el;
    }

    ngOnChanges() {
        const backgroundColor = this.pillColor(this.pill);
        this.el.nativeElement.style.backgroundColor = backgroundColor;
        this.el.nativeElement.style.color = this.backgroundToForeground(backgroundColor);
    }

    private backgroundToForeground(color: string): string {
        return [this.dark, this.darkgreen, this.darkred, this.black, this.purple].includes(color)
            ? this.light
            : this.dark;
    }

    private pillColor(input: any): string {
        const status = input.status?.toUpperCase();
        const module = input.module;

        if (module === 'VesselVisitStatus') {
            switch (status) {
                case 'ANN':
                    return this.blue;
                case 'ARR':
                    return this.green;
                case 'DEP':
                    return this.purple;
                case 'CAN':
                    return this.yellow;
                case 'REJ':
                    return this.red;
            }
        }
        if (module === 'VesselStatus') {
            switch (status) {
                case 'AC':
                    return this.green;
                case 'IN':
                    return this.red;
            }
        }
        if (module === 'BerthRequestStatus' || module === 'ServiceRequestStatus' || module === 'ContainerVisitStatus') {
            switch (status) {
                case 'APP':
                    return this.green;
                case 'CAN':
                    return this.yellow;
                case 'COM':
                    return this.darkgreen;
                case 'DRA':
                    return this.orange;
                case 'REJ':
                    return this.red;
                case 'WFA':
                    return this.blue;
            }
        }
        if (module === 'BerthRequestStatus') {
            switch (status) {
                case 'RFU':
                    return this.black;
            }
        }
        if (module === 'ServiceRequestStatus') {
            switch (status) {
                case 'WIP':
                    return this.black;
            }
        }
        if (module === 'ContainerVisitStatus') {
            switch (status) {
                case 'INP':
                    return this.green;
                case 'LTP':
                    return this.purple;
                case 'RTE':
                    return this.blue;
                case 'RTL':
                    return this.darkgreen;
            }
        }
        if (module === 'DeliveryOrderStatus') {
            switch (status) {
                case 'CAN':
                    return this.red;
                case 'COM':
                    return this.green;
                case 'DRA':
                    return this.orange;
                case 'RLS':
                    return this.purple;
            }
        }
        return this.grey;
    }
}
