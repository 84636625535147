<div class="flex h-full items-center justify-center">
    <div class="w-full rounded-lg bg-card shadow md:max-w-[1024px]">
        <div class="grid grid-cols-2">
            <div
                class="col-span-2 h-60 rounded-s-lg bg-cover md:col-span-1 md:h-full"
                style="background-image: url('/assets/img/front.jpg')"></div>

            <div class="col-span-2 flex w-full flex-col gap-6 px-8 py-4 md:col-span-1 md:p-12">
                <div class="flex flex-wrap items-center justify-end gap-2">
                    @if (isDarkTheme()) {
                        <app-button tooltip="Light mode" variant="link" icon="faSolidSun" (clicked)="toggleTheme()" />
                    } @else if (!isDarkTheme()) {
                        <app-button tooltip="Dark mode" variant="link" icon="faSolidMoon" (clicked)="toggleTheme()" />
                    }
                    <div placement="bottom-right" kendoTooltip title="{{ 'Change Language' | translate }}">
                        @if (currentLanguage()) {
                            <kendo-dropdownbutton
                                buttonClass="bg-card text-card-foreground border-none transition-none hover:bg-primary/20 bg-none"
                                [data]="languages">
                                @if (showLanguageFlag) {
                                    <span class="p-0" [ngClass]="currentLanguage().iconClass"></span>
                                } @else {
                                    {{ currentLanguage().languageId | uppercase }}
                                }
                            </kendo-dropdownbutton>
                        }
                    </div>
                </div>

                <div class="flex flex-col items-center justify-center gap-2 pb-6 text-2xl">
                    <a routerLink="/home" title="{{ 'Home' | translate }}">
                        @if (logo) {
                            <img class="w-10" [src]="logo" />
                        }
                    </a>
                    <h1>{{ title | translate }}</h1>
                    @if (environment) {
                        <span [attr.style]="environmentColor">
                            {{ environment | translate }}
                        </span>
                    }
                </div>

                <form class="flex flex-col gap-4" (submit)="onSubmit()">
                    <app-control
                        name="username"
                        placeholder="{{ 'Username' | translate }}"
                        autofocus
                        [ngClass]="{ isInvalid: !!errorMessage }"
                        [(ngModel)]="username" />
                    <app-control
                        type="password"
                        name="password"
                        placeholder="{{ 'Password' | translate }}"
                        [ngClass]="{ isInvalid: !!errorMessage }"
                        [(ngModel)]="password" />
                    <div class="flex flex-col gap-4 text-center">
                        <app-button class="w-full rounded-xl" type="submit" variant="primary" [disabled]="isBusy">
                            {{ 'Sign In' | translate }}
                        </app-button>
                        @if (showRegister || showForgetPassword) {
                            <div class="flex flex-wrap items-center justify-between">
                                @if (showRegister) {
                                    <a class="rounded-md p-1 hover:bg-primary/20" href="#">
                                        {{ 'Register' | translate }}
                                    </a>
                                }
                                @if (showForgetPassword) {
                                    <a class="rounded-md p-1 hover:bg-primary/20" href="#">
                                        {{ 'Forgot password' | translate }}
                                    </a>
                                }
                            </div>
                        }
                        @if (certificateLogin || googleLogin || appleLogin || facebookLogin) {
                            <div class="flex flex-wrap items-center text-muted">
                                <hr class="h-px w-5/12 bg-muted" />
                                <div class="w-2/12">{{ 'or' | translate }}</div>
                                <hr class="h-px w-5/12 bg-muted" />
                            </div>
                        }

                        @if (certificateLogin) {
                            <app-button
                                class="w-full rounded-xl"
                                variant="warning"
                                icon="faSolidKey"
                                iconPosition="end">
                                {{ 'Sign in with Certificate' | translate }}
                            </app-button>
                        }
                        @if (googleLogin) {
                            <app-button
                                class="w-full rounded-xl"
                                variant="danger"
                                icon="bootstrapGoogle"
                                iconPosition="end">
                                {{ 'Sign in with Google' | translate }}
                            </app-button>
                        }
                        @if (facebookLogin) {
                            <app-button
                                class="w-full rounded-xl"
                                variant="primary"
                                icon="bootstrapFacebook"
                                iconPosition="end">
                                {{ 'Sign in with Facebook' | translate }}
                            </app-button>
                        }
                        @if (appleLogin) {
                            <app-button
                                class="w-full rounded-xl"
                                variant="secondary"
                                icon="bootstrapApple"
                                iconPosition="end">
                                {{ 'Sign in with Apple' | translate }}
                            </app-button>
                        }
                    </div>
                </form>

                <div class="flex flex-wrap items-center justify-between gap-4 pt-4 text-muted">
                    <div>2024 &copy; {{ 'All rights reserved' | translate }}</div>
                    @if (showTermsAndConditions) {
                        <div>
                            <a href="#">{{ 'Terms and conditions' | translate }}</a>
                        </div>
                    }
                    <div>{{ 'Version' | translate }} {{ version }}</div>
                </div>
            </div>
        </div>
    </div>
</div>
