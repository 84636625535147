import { Component } from '@angular/core';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';

export interface DialogConfirmOptions {
    title: string;
    message: string;
    hideCancel?: boolean;
    cancelText?: string;
    confirmText?: string;
    canSave?: (model: any) => boolean;
}

@Component({
    template: `
        <kendo-dialog-titlebar class="bg-background px-4 py-6 font-bold">
            {{ options?.title | translate }}
        </kendo-dialog-titlebar>
        <div class="flex h-full items-center justify-center p-4">
            {{ options?.message | translate }}
        </div>
        <kendo-dialog-actions class="px-4" layout="end">
            <app-button *ngIf="!options.hideCancel" variant="info" (clicked)="dialog.close(false)">
                {{ options?.cancelText | translate }}
            </app-button>
            <app-button variant="primary" [disabled]="!canSavePredicate(model)" (clicked)="dialog.close(true)">
                {{ options?.confirmText | translate }}
            </app-button>
        </kendo-dialog-actions>
    `
})
export class DialogConfirmComponent extends DialogContentBase {
    options: DialogConfirmOptions = {
        title: 'Dialog',
        message: 'Message',
        hideCancel: false,
        cancelText: 'Cancel',
        confirmText: 'Confirm'
    };

    canSavePredicate: (model: any) => boolean;
    model: any;

    constructor(public override dialog: DialogRef) {
        super(dialog);
    }

    public initialize(options: DialogConfirmOptions) {
        for (const [key, value] of Object.entries(options)) {
            if (value !== undefined && value !== null) this.options[key] = value;
        }
        this.canSavePredicate = this.options.canSave || this.canSave.bind(this);
    }

    private canSave(model: any): boolean {
        return true;
    }
}
